.Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.Aligner-item {
    max-width: 50%;
}

.Aligner-item--top {
    align-self: flex-start;
}

.Aligner-item--bottom {
    align-self: flex-end;
}